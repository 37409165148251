import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import Helmet from "react-helmet";

import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";
import ImageGrid from "../../components/images/grid";

class ImagesForArtistPage extends React.Component {
  constructor({ data, pageContext }) {
    super();

    this.data = data;
    this.artistName = pageContext.artistName;
  }

  render() {
    const images = (this.data.allImagesJson) ?
                    this.data.allImagesJson.edges : null;
    const narratives = (this.data.allNarrativesJson) ?
                        this.data.allNarrativesJson.edges : null;
    const artists = (this.data.allArtistsJson) ?
                     this.data.allArtistsJson.edges : null;
    const venues = (this.data.allVenuesJson) ?
                    this.data.allVenuesJson.edges : null;
    const exhibits = (this.data.allExhibitsJson) ?
                    this.data.allExhibitsJson.edges : null;
    
    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <Helmet>
            <title>{`Images with ${this.artistName}`}</title>
          </Helmet>
          <PageSection background="dark-accent">
            <h1 className="page-title">Images with {this.artistName}</h1>
          </PageSection>
          <PageSection topPadding="md">
            <ImageGrid images={images} 
                      narratives={narratives}
                      artists={artists}
                      venues={venues}
                      exhibits={exhibits} />
          </PageSection>
        </PageContainer>
      </Layout>
    )
  }
}

export default ImagesForArtistPage;

export const query = graphql`
  query ImagesForArtistQuery($slug: String!) {
    allImagesJson(filter: {artists: {eq: $slug}}) {
      edges {
        node {
          reference
          fullPath
          thumbPath
          alt
          caption
          credit
          narratives
          artists
          venues
          year
        }
      }
    },
    allExhibitsJson {
      edges {
        node {
          reference
          long_title
        }
      }
    }
    allNarrativesJson {
      edges {
        node {
          reference
          title
          exhibit
        }
      }
    },
    allArtistsJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    allVenuesJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
  }
`;